<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Professional_details") }}
            </h1>
        </div>
        <div>
            <b-form class="customized-form w-100">
                <ValidationObserver ref="edit_candidate_education" tag="div">
                    <b-row class="px-3">
                        <div class="col-lg-6 col-12">
                            <ValidationProvider name="education" v-slot="{ errors, classes }">
                                <div class="custom-placeholder">
                                    {{ search ? '' : $gettext("Select_education") }}
                                </div>
                                <v-select id="user-education" clearable multiple class="form-control" :class="classes"
                                    v-model="education" :options="educationOption"
                                    :get-option-label="(option) => option.ms_edu_degree" @search="searchEdu">
                                </v-select>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                            <!-- <ValidationProvider name="education" v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="education">
                                            <b-form-input id="education" v-model="education" size="sm" type="text"
                                                placeholder="Highest education received"></b-form-input>
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider> -->
                        </div>
                        <div class="selected-industry-skill m-2 px-3 col-lg-6 col-12">
                            <span v-for="(edu, i) in education" class="vs__selected" :key="i">
                                {{ edu.ms_edu_degree }}
                                <img style="cursor: pointer;" @click="removeEdu(i)" class="ml-2"
                                    src="@/assets/zaajira-candidate/assets/icons/cross.svg" alt="" />
                            </span>
                        </div>
                    </b-row>
                    <b-row class="px-3 mt-2">
                        <div class="col-12 field-label"> {{ $gettext("Upload_certificate") }}</div>
                    </b-row>
                    <b-row class="px-3">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <label for="certificate">

                                <img style="cursor: pointer; width: 6rem;"
                                    src="@/assets/zaajira-candidate/assets/icons/certificate_icon.png" alt="" />
                            </label>
                            <input style="position: absolute; left:-100000000px" id="certificate" name="certificate"
                                accept="image/jpeg,image/gif,image/png,application/pdf" type="file"
                                @change="uploadCertificate" />
                        </div>
                    </b-row>
                    <b-row class="px-3 mb-2">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <a v-if="certificate_path !== null" :href="certificate_path" target="_blank"
                                rel="noopener noreferrer">
                                {{ $gettext("View_Certificate") }}
                            </a>
                        </div>
                    </b-row>
                    <b-row class="px-3 my-2">
                        <div class="col-12 field-label"> {{ $gettext("Work_experience") }}</div>
                    </b-row>
                    <b-row class="p-1 px-3">
                        <ValidationProvider name="Total_experience" v-slot="{ errors, classes }">
                            <div style="gap:10px" class="col-12 d-flex ">
                                <b-form-radio :class="classes" class="dnd-show" v-model="isExperience" name="isExperience"
                                    value="Yes">{{ $gettext("Yes") }}</b-form-radio>
                                <b-form-radio :class="classes" class="dnd-show" v-model="isExperience" name="isExperience"
                                    value="No">{{ $gettext("No") }}</b-form-radio>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row v-if="isExperience === 'Yes'" class=" p-1 px-3">
                        <div class="col-lg-3 col-md-12">
                            <ValidationProvider name="Total_experience" v-slot="{ errors, classes }">
                                <v-select placeholder="Total Experience" class="form-control" :class="classes"
                                    v-model="Total_experience" :options="TotalExpOption"> </v-select>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <ValidationProvider name="job_title" v-slot="{ errors, classes }">
                                <b-form-group label="" label-for="job_title">
                                    <b-form-input id="job_title" v-model="job_title" size="sm" type="text"
                                        placeholder="Current job title"></b-form-input>
                                </b-form-group>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </b-row>
                    <b-row v-if="isExperience === 'Yes'" class=" p-1 px-3">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                            <ValidationProvider name="currency" v-slot="{ errors, classes }">
                                <v-select placeholder="Currency" class="form-control" :class="classes" v-model="currency"
                                    :options="currencyOption" :get-option-label="(option) => option.currency_code">
                                </v-select>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                            <ValidationProvider name="current_salary" v-slot="{ errors, classes }">
                                <b-form-group label="" label-for="current_salary">
                                    <b-form-input id="current_salary" type="number" v-model="current_salary" size="sm"
                                        min="0" max="10000000000000000" placeholder="Current Monthly Salary"></b-form-input>
                                </b-form-group>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                    </b-row>

                    <div class="col-12 my-4 btn-flex-fd-row-jc-sb">
                        <!-- <span @click="goBack" class="go-back">Back</span> -->
                        <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{ $gettext("Submit")
                        }}</b-button>
                    </div>

                    <!-- <div class="col-12 mb-4">
                                    <b-button @click.prevent.stop="ValidateInput" class="btn full-width-btn" variant="primary"
                                        size="sm">Next</b-button>
                                </div> -->
                </ValidationObserver>
            </b-form>
        </div>
    </div>
</template>

<script>
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { apipublic } from "@/config";

export default {
    name: "Support",
    components: {},
    data() {
        return {
            search: null,
            certificate_path: null,
            education: null,
            isExperience: 'Yes',
            Total_experience: null,
            job_title: null,
            currency: null,
            current_salary: null,
            currencyOption: [],
            educationOption: [],
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        goBack() {
            this.$router.push({ path: 'skill-details' })
        },
        removeEdu(index) {
            console.log(index);
            this.education && this.education.splice(index, 1)
        },
        searchEdu(s, loading) {
            this.search = s
        },
        async getAllMasterData() {
            try {
                const currencyRes = await MasterServices.GetAllCurrency()
                console.log('currencyRes', currencyRes);
                this.currencyOption = currencyRes.data.data
                const educationRes = await MasterServices.GetAllEducation()
                console.log('educationRes', educationRes.data.data.types);
                this.educationOption = educationRes.data.data.types
                const userData = this.$store.getters.getLoggedUser
                const certificateRes = await CandidateService.getCertificate(userData.userId)
                console.log('certificateRes', certificateRes.data.data);
                this.certificate_path = certificateRes.data.data.certificate_path ? apipublic + certificateRes.data.data.certificate_path : null

            } catch (error) {

            }
        },
        async PreFilledData() {
            const loader = this.$loading.show();
            await this.getAllMasterData()
            const userData = this.$store.getters.getLoggedUser
            CandidateService.getUserByUserID(userData.userId)
                .then(async (res) => {
                    console.log("res>>>", res.data.data[0])
                    this.education = this.educationOption.filter((ed) => ed.ms_edu_id === res.data.data[0].ms_edu_id)
                    this.isExperience = res.data.data[0].experiance_is_active === 'Y' ? 'Yes' : 'No'
                    this.Total_experience = res.data.data[0].experiance_year
                    this.job_title = res.data.data[0].designation
                    this.currency = this.currencyOption.filter((c) => c.currency_id === res.data.data[0].currency_id)
                    this.current_salary = res.data.data[0].current_salary
                    loader.hide();
                })
                .catch((error) => {
                    loader.hide();
                    console.log(error);
                })
        },
        async uploadCertificate(e) {
            console.log('file', e.target.files[0]);
            let form_data = new FormData();
            form_data.append('certificate', e.target.files[0]);
            let loader = this.$loading.show();
            try {
                const res = await CandidateService.uploadCertificate(form_data)
                if (res.status && res.data.data.certificate_path) {
                    this.$toasted.show(`Certificate uploaded`, {
                        duration: 6000,
                        type: "success",
                        icon: "check",
                    });
                    console.log('res', res);
                    this.certificate_path = res.data.data.certificate_path ? apipublic + res.data.data.certificate_path : null
                }
            } catch (error) {
                console.log(error);
            }
            loader.hide()

        },
        ValidateInput() {
            return this.$refs.edit_candidate_education.validate().then(async (result) => {
                if (result) {
                    console.log(result);


                    const payload = {
                        "education_id": this.education[0]?.ms_edu_id !== undefined ? this.education[0].ms_edu_id : null,
                        "work_experience": this.isExperience,
                        "total_experience": this.Total_experience,
                        "current_job": this.job_title,
                        "currency_id": this.currency.currency_id,
                        "current_salary": this.current_salary
                    }
                    console.log('payload', payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            console.log(res);
                            console.log('res', res.data.data);
                            console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });

                            // this.$store.dispatch("onSetSelectedSkills", storePayload);

                            // this.$router.push({ name: "User-Profile" })
                            loader.hide();
                        })
                        .catch((error) => {
                            loader.hide();
                            console.log(error);
                        })
                }
            })
        }
    },
    computed: {
        TotalExpOption() {
            // Generate an array of numbers from 1 to 100
            return Array.from({ length: 100 }, (_, index) => index + 1).map((number) => (number));
        },
    }
}
</script>

<style></style>